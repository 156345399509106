.App {
  min-height: 100vh;
  text-align: center;
  overflow-x: hidden;
  background-color: black;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;

}


.logospace {
  margin-top: 20vh;
}

@media (max-width: 768px) {
  .main-logo {
    margin-top: 20vh;
  }
  .titlelogo {
    max-width: 100vw;
  }
}