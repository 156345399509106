@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

* {
  box-sizing: border-box;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  min-height: 100vh;
  max-width: 100%;
  
}

html {
  scroll-behavior: smooth;
}

#widget8 {
  display: none;
}

.nft-link {
  
  font-family: "Luckiest Guy", cursive;
  color: rgb(29, 163, 253);
  background-color: rgb(48, 48, 48);
  text-decoration-color: rgb(250, 98, 42);
  border-radius: 20px;
  border: 1px solid rgb(122, 122, 122);
  transition: all 0.3s ease; /* Smooth transition effect */
}

.nft-link:hover {
  color: rgb(250, 98, 42); /* Change text color on hover */
  background-color: rgb(29, 163, 253); /* Change background color on hover */
  border: 1px solid rgb(250, 98, 42); /* Change border color on hover */
  text-decoration-color: rgb(29, 163, 253); /* Change text decoration color on hover */
}
